<template>
  <page-content title="产品功能定义">
    <common-table
            ref="table"
            path="web/product/model"
            :queryParams="queryParams"
            :columns="columns"
    >

      <template slot="search">
        <a-button ghost type="primary" @click="add" >新增</a-button>
      </template>

      <template slot="icon" slot-scope="{record}">
        <a-avatar shape="square" :size="40" :src="record.devicePicture">{{record.name}}</a-avatar>
      </template>
      <template slot="operation" slot-scope="{record}">
        <action-edit @click="edit(record)"></action-edit>
        <action-delete @click="remove(record)"></action-delete>
        <!--          <action-view @click="view(record)"></action-view>-->
      </template>

    </common-table>

    <model-edit ref="editDrawer" @success="getList"></model-edit>
  </page-content>
</template>

<script>
import ModelEdit from "./ModelEdit"
export default {
  components: {
    ModelEdit
  },
  data () {
    return {
      queryParams:{
        productKey:this.$route.params.productKey
      }
    }
  },

  computed: {
    columns () {
      return [
        {
          title: '索引',
          dataIndex: 'index',
          width: 80,
          customRender:(text)=>{
            return "0x"+
              ("00"+parseInt(text/256).toString(16)).substr(-2) +
              ("00"+parseInt(text%256).toString(16)).substr(-2)
          }
        },
        {
          title: '名称',
          dataIndex: 'name',
          width: 120,
        },
        {
          title: '长度',
          dataIndex: 'length',
          customRender:(text)=>text+" Byte",
          width: 80,
        },
        {
          title: '备注',
          dataIndex: 'remark',
        },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createdAt',
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
          width: 80
        }
      ]
    }
  },
  methods: {

    add(){
      this.$refs.editDrawer.show({
        productKey:this.queryParams.productKey
      })
    },
    edit (record) {
      this.$refs.editDrawer.show(record)
    },
    view(record){

    },
    getList () {
      this.$refs.table.getData()
    },
    remove (record) {
      let that = this
      this.$confirm({
        title: '是否删除该记录?',
        content: '该操作不可撤销',
        centered: true,
        onOk () {
          that.$delete('web/product/model/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
